<template>
  <v-container>
    <post
      v-for="p in posts"
      :key="p"
      :post = "p"
    />
    <v-btn
      v-if="!end"
      block
      rounded
      color="orange"
      class="my-10 white--text"
      text
      @click="more"
      :loading="loading"
    >load more</v-btn>
    <v-btn
      v-else
      block
      rounded
      color="orange"
      class="my-10 white--text"
      text
      disabled
    >no more posts</v-btn>
  </v-container>
</template>

<script>
import Post from './Post.vue'

export default {
  data: () => ({
    end: false,
    loading: false
  }),
  computed: {
    posts() {
      return this.$store.state.posts.trendingPosts
    },
  },
  methods: {
    async more() {
      this.loading = true
      const res = await this.$store.dispatch('getMoreTrendingPosts')
      if (!res) {
        this.end = true
      }
      this.loading = false
    },
    async fetch() {
      await this.$store.dispatch('getTrendingPosts', this.limit)
    }
  },
  async created() {
    await this.fetch()
  },
  components: { Post }
}
</script>

<style>

</style>