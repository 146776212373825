<template>
  <div class="d-flex flex">
    <v-btn to="/" class="pl-0" small plain>
      <v-icon class="mr-3">mdi-arrow-left</v-icon>
      Back
    </v-btn>

    <v-spacer></v-spacer>

    <div class="text-truncate grey--text text--darken-2">
      {{search}}
    </div>
  </div>
</template>


<script>
export default {
  computed: {
    search() {
      return this.$route.params.search
    }
  }
}
</script>

<style>

</style>