<template>
  <div>
    <div class="text-uppercase font-weight-black orange--text">
      <v-badge content="18+" color="red" class="mt-5">
        Ghost Arena
      </v-badge>
    </div>
    <div class="text-caption grey--text">
      👀 Share and discuss anonymously
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>