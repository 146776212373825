<template>
  <v-app>
    <app-bar />
    <v-main>
      <add-button v-if="this.$route.name === 'Home'" />
      <router-view></router-view>
      <add-comment @commented="commented" v-if="this.$route.name === 'Post' && !loading" />
    </v-main>
  </v-app>
</template>

<script>
import AddComment from './components/comments/AddComment.vue';
import AppBar from './components/root/AppBar/AppBar.vue';
import AddButton from './components/thoughts/AddButton.vue';
import Loader from './components/root/Loader.vue';

export default {
  components: { AddButton, AppBar, AddComment, Loader },
  name: 'App',
  data: () => ({
  }),
  methods: {

  },
  computed: {
    reports() {
      return this.$store.state.reports
    },
  },
  async created() {
    // const reports = JSON.parse(localStorage.getItem("reports"));
    // this.$store.commit('setReports', reports)
    // console.log(this.reports)
    // console(typeof this.reports)
    // Retrieving the string
    let retString = localStorage.getItem("reports")

    // Retrieved array
    let retArray = JSON.parse(retString)
    let i = 0;
    let arrayLength = retArray.length;
    while(i < arrayLength ) {
      let val = retArray[i];
      i++;
      this.$store.commit('pushReport', val)
    }
    // console.log(typeof retArray)
    // console.log(this.reports)
  }
};
</script>
