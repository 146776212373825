<template>
  <v-text-field
    size="100%"
    v-model.trim="searchText"
    dense
    filled
    rounded
    clearable
    placeholder="Search"
    prepend-inner-icon="mdi-magnify"
    class="pt-6 shrink expanding-search"
    :class="{ closed: searchBoxClosed && !searchText }"
    @keyup.enter="onSearch"
    @focus="searchBoxClosed = false"
    @blur="searchBoxClosed = true"
  ></v-text-field>
</template>

<script>
export default {
	data: () => ({
		searchText: null,
		searchBoxClosed: true
	}),
  methods: {
    onSearch() {
      return this.$router.push('/s/' + this.searchText)
    }
  },
  watch: {
    searchBoxClosed() {
      this.$store.commit('setSearchBoxStatus', this.searchBoxClosed)
      if (this.searchBoxClosed === true) {
        this.searchText = null
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.v-input.expanding-search
	transition: max-width 0.2s
	.v-input__slot
	&.closed
		max-width: 70px
</style>