import { db } from '@/main.js'
import { collection, query, getDocs, orderBy, limit, addDoc, serverTimestamp, startAfter } from "firebase/firestore";

export default {
  state: {
    trendingPosts: [],
    newPosts: [],
    lastVisibleTrending: null,
    lastVisibleNew: null
  },
  mutations: {
    lastVisibleTrending(s, paylod) {
      s.lastVisibleTrending = paylod
    },
    lastVisibleNew(s, paylod) {
      s.lastVisibleNew = paylod
    },
    setTrendingPosts(s, payload) {
      s.trendingPosts = payload
    },
    pushTrendingPosts(s, payload) {
      s.trendingPosts.push(payload)
    },
    setNewPosts(s, payload) {
      s.newPosts = payload
    },
    pushNewPosts(s, payload) {
      s.newPosts.push(payload)
    },
  },
  actions: {
    async addNewPost({commit, dispatch}, payload) {
      if (!payload || !payload.body) {
        return false
      }
      try {
        const docRef = await addDoc(collection(db, "posts"), {
          ...payload,
          commentsCounter: 0,
          reports: 0,
          added: serverTimestamp()
        });
        // console.log("Document written with ID: ", docRef.id);
        await dispatch('getNewPosts')
        localStorage.setItem('posted', Date.now())
        commit('noPosting')
        commit('setTab', 1)
        return true
      } catch (e) {
        console.error("Error adding document: ", e);
        return false
      }
    },
    async getMoreTrendingPosts({commit, dispatch}) {
      const l = this.getters.lastVisibleTrendingElement

      const q = query(collection(db, "posts"), orderBy("commentsCounter", "desc"), startAfter(l), limit(2));
      const querySnapshot = await getDocs(q);
      commit('lastVisibleTrending', querySnapshot.docs[querySnapshot.docs.length-1])
      querySnapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id
        }
        commit('pushTrendingPosts', data)
      });
      if (!querySnapshot.docs.length) {
        return false
      }
      return true
    },
    async getTrendingPosts({commit, dispatch}) {
      const posts = []

      const q = query(collection(db, "posts"), orderBy("commentsCounter", "desc"), limit(5));
      
      const querySnapshot = await getDocs(q);
      commit('lastVisibleTrending', querySnapshot.docs[querySnapshot.docs.length-1])
      
      querySnapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id
        }
        posts.push(data)
      });
      commit('setTrendingPosts', posts)
      return posts
    },
    async getMoreNewPosts({commit, dispatch}) {
      const l = this.getters.lastVisibleNewElement

      const q = query(collection(db, "posts"), orderBy("added", "desc"), startAfter(l), limit(2));
      const querySnapshot = await getDocs(q);
      commit('lastVisibleNew', querySnapshot.docs[querySnapshot.docs.length-1])
      querySnapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id
        }
        commit('pushNewPosts', data)
      });
      if (!querySnapshot.docs.length) {
        return false
      }
      return true
    },
    async getNewPosts({commit, dispatch}) {
      const posts = []

      const q = query(collection(db, "posts"), orderBy("added", "desc"), limit(5));
      
      const querySnapshot = await getDocs(q);
      commit('lastVisibleNew', querySnapshot.docs[querySnapshot.docs.length-1])
      
      querySnapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id
        }
        posts.push(data)
      });
      commit('setNewPosts', posts)
      return posts
    },
  },
  getters: {
    lastVisibleTrendingElement(s) {
      return s.lastVisibleTrending
    },
    lastVisibleNewElement(s) {
      return s.lastVisibleNew
    }
  }
}
