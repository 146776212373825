import { db } from '@/main.js'
import { increment, doc, getDoc, collection, updateDoc, query, getDocs, orderBy, limit, addDoc, serverTimestamp, startAfter } from "firebase/firestore";

export default {
  state: {
    comments: [],
    lastVisibleComment: null
  },
  mutations: {
    lastVisibleComment(s, paylod) {
      s.lastVisibleComment = paylod
    },
    pushComment(s, payload) {
      s.comments.push(payload)
    },
    setComments(s,payload) {
      s.comments = payload
    },
    clearComments(s) {
      s.comments = []
    },
  },
  actions: {
    async addComment({commit, dispatch}, payload) {
      if (!payload || !payload.body) {
        return false
      }
      
      try {
        const docRef = await addDoc(collection(db, "postsData", payload.id, "comments"), {
          body: payload.body,
          reports: 0,
          added: serverTimestamp()
        });

        commit('pushComment', {...payload, id: docRef.id})
        const update = {
          id: payload.id,
        }
        await dispatch('updateDocCommentsCounter', update)
        return true
      } catch (e) {
        console.error("Error adding document: ", e);
        return false
      }
    },
    async updateDocCommentsCounter({commit, dispatch}, data) {
      const docRef = doc(db, "posts", data.id);
    
      await updateDoc(docRef, {
        commentsCounter: increment(1)
      });
    },
    async getComments({commit, dispatch}, id) {
      const comments = []
      let data = null
      const q = query(collection(db, "postsData", id, "comments"), orderBy("added", "desc"), limit(5));
      
      const querySnapshot = await getDocs(q);
      commit('lastVisibleComment', querySnapshot.docs[querySnapshot.docs.length-1])

      querySnapshot.forEach((doc) => {
        data = {
          ...doc.data(),
          id: doc.id
        }
        comments.push(data)
      });
      commit('setComments', comments)
      return comments
    },
    async loadMoreComments({commit, dispatch}, id) {
      const l = this.getters.lastVisibleComment

      const q = query(collection(db, "postsData", id, "comments"), orderBy("added", "desc"), startAfter(l), limit(2));
      const querySnapshot = await getDocs(q);
      commit('lastVisibleComment', querySnapshot.docs[querySnapshot.docs.length-1])
      
      querySnapshot.forEach((doc) => {
        let data = {
          ...doc.data(),
          id: doc.id
        }
      
        commit('pushComment', data)
      });
      if (!querySnapshot.docs.length) {
        return false
      }
      return true
    }
  },
  getters: {
    lastVisibleComment(s) {
      return s.lastVisibleComment
    }
  }
}
