<template>
  <div>
    <v-card
      flat
      rounded="xl"
      class="mb-3"
      color="grey lighten-4"
    >
      <v-card-text class="black--text py-2">
        {{ post.body }}
        <!-- <div class="text-caption font-italic grey--text">
          {{ (post.added).toDate() }}
        </div> -->
      </v-card-text>
      <v-divider color="white"></v-divider>
      <v-card-actions
        class="px-4 grey--text text--lighten-1 font-weight-bold justify-center"
        @click="go(post.id)"
      >
        {{ post.commentsCounter || '0' }} comments
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    post: {
      type: Object
    }
  },
  computed: {
    likes() {
      return this.$store.state.foundLikes
    },
    ip() {
      return this.$store.state.ip
    }
  },
  methods: {
    dislike(postId) {
      alert('dislike')
    },
    like(postId) {
      const data = {
        id: postId,
        ip: this.ip
      }
      this.$store.dispatch('addLike', data)
      this.post.likesCounter++
    },
    go(id) {
      this.$router.push('/p/' + id)
    }
  },
}
</script>

<style>

</style>