<template>
  <div class="d-flex flex">
    <v-btn to="/" class="pl-0" small plain>
      <v-icon class="mr-3">mdi-arrow-left</v-icon>
      Back
    </v-btn>

    <v-spacer></v-spacer>

    <div class="text-caption pt-1 grey--text text--darken-2">
      Bookmark this page to save
      <v-icon small class="ml-1" color="grey darken-2">mdi-bookmark</v-icon>
    </div>
    <div
      v-if="reported"
      class="mt-1 ml-2 text-caption text-right font-weight-bold red--text text--darken-3"
    >Reported</div>
    <v-btn v-else color="red darken-1" class="pr-0" small plain @click="report">
      Report
      <v-icon small class="ml-1">mdi-virus</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  data: () => ({
  }),
  computed: {
    reported() {
      return this.reports.includes(this.$route.params.id)
    },
    reports() {
      return this.$store.state.reports
    }
  },
  methods: {
    report() {
      this.$store.dispatch('reportPost', this.$route.params.id)
    }
  },
}
</script>

<style>

</style>