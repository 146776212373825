<template>
  <v-tabs
    v-model="tabs.selectedTab"
    grow
  >
    <v-tabs-slider color="orange"></v-tabs-slider>
    <v-tab
      v-for="t in tabs.tabs"
      :key="t"
      :name="t"
    >
      {{ t }}
    </v-tab>
  </v-tabs>
</template>

<script>
export default {
  data: () => ({
    currentTab: null
  }),
  computed: {
    tabs() {
      return this.$store.state.postsTabState
    }
  },
  watch: {
    currentTab() {
      this.$store.commit('setTab', this.currentTab)
    }
  }
}
</script>

<style>

</style>