import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyALZvHLNl9LLrKWWqCNrlioCK6yg0UE7As",
  authDomain: "trending-thoughts.firebaseapp.com",
  databaseURL: "https://trending-thoughts-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "trending-thoughts",
  storageBucket: "trending-thoughts.appspot.com",
  messagingSenderId: "310427671143",
  appId: "1:310427671143:web:7fa4171b5f924b754412c2",
  measurementId: "G-GYDY8NZ85S"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);
const analytics = getAnalytics(app);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
