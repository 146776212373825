import Vue from 'vue'
import Vuex from 'vuex'
import { db } from '@/main.js'
import { getDoc, doc, increment, updateDoc } from "firebase/firestore";
import posts from './posts'
import comments from './comments'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    reports: [],
    postsTabState: {
      tabs: ['trending', 'new'],
      selectedTab: 0,
    },
    canPost: true,
    loading: true,
    searchBoxClosed: true,
  },
  mutations: {
    setReports(s, payload) {
      s.reports = payload
    },
    pushReport(s, payload) {
      s.reports.push(payload)
    },
    setSearchBoxStatus(s, payload) {
      s.searchBoxClosed = payload
    },
    startLoading(s) {
      s.loading = true
    },
    stopLoading(s) {
      s.loading = false
    },
    noPosting(s) {
      s.canPost = false
    },
    setPosts(s, payload) {
      s.posts = payload
    },
    clearPosts(s) {
      s.posts = []
    },
    setTab(s, payload) {
      s.postsTabState.selectedTab = payload
    },
  },
  actions: {
    async getThePost({commit, dispatch}, id) {
      const docRef = doc(db, "posts", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // console.log("Document data:", docSnap.data());
        return docSnap.data()
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
        return false
      }
    },
    async reportPost({commit, dispatch}, id) {
      const docRef = doc(db, "posts", id);
    
      await updateDoc(docRef, {
        reports: increment(1)
      });

      commit('pushReport', id)
      // localStorage.setItem("reports", JSON.stringify(this.getters.reports));
      localStorage.setItem("reports", JSON.stringify(this.getters.reports))
    },
    async reportComment({commit, dispatch}, data) {
      const docRef = doc(db, "postsData", data.post, "comments", data.comment);
    
      await updateDoc(docRef, {
        reports: increment(1)
      });
      commit('pushReport', data.comment)
      // localStorage.setItem("reports", JSON.stringify(this.getters.reports));
      localStorage.setItem("reports", JSON.stringify(this.getters.reports))
    }
  },
  modules: {
    posts,
    comments
  },
  getters: {
    reports(s) {
      return s.reports
    }
  }
})