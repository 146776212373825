<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="orange"
          fixed
          bottom
          x-large
          right
          v-bind="attrs"
          v-on="on"
          fab
          :disabled="parseInt(((Date.now() - local) / 1000 / 60)) < 2 || !canPost"
          >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-card rounded="xl" :disabled="loading">
        <v-card-title>
          You post anonymously

          <v-spacer></v-spacer>
          <v-btn
            @click="close"
            icon
          ><v-icon color="red">mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-subtitle class="pb-1">Be polite and respectful •  You cannot post often</v-card-subtitle>
        <v-card-text>
          <v-alert v-if="error" type="error" dense>
            {{ error }}
          </v-alert>
          <v-form v-model="isValid">
            <v-textarea
              label="Share what's on your mind or tell a story"
              color="grey"
              v-model="message"
              :rules="messageRules"
              counter
              required
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        
        <v-card-actions>
          <div class="red--text font-weight-bold text-caption">
            <v-icon small color="red">mdi-alert</v-icon>
            You cannot delete or modify your message after sending. It could be deleted by Admin or AI
          </div>
          <v-spacer></v-spacer>
          <v-btn
            text
            :disabled="!isValid"
            @click="addNewPost"
          >
            Post
            <v-icon class="ml-1">mdi-send</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    isValid: false,
    error: null,
    loading: false,
    message: '',
    messageRules:
    [
      (v) => !!v || "Message is required",
      (v) => (v && v.length > 10) || "Message must be more than 10 characters",
      (v) => (v && v.length < 1251) || "Message must be 1250 characters or less",
    ],
  }),
  computed: {
    local() {
      return localStorage.getItem('posted') || 'nothing'
    },
    canPost() {
      return this.$store.state.canPost
    }
  },
  methods: {
    close() {
      this.message = ''
      this.dialog = false
    },
    async addNewPost() {
      this.loading = true
      const message = {
        body: this.message,
        score: 0
      }
      const res = await this.$store.dispatch('addNewPost', message)
      if (res) {
        this.dialog = false
        this.message = ''
        this.loading = false
      } else {
        this.error = 'Somthing went wrong'
        this.loading = false
      }

    }
  }
}
</script>