<template>
  <v-card
    height="100vh"
    flat
    tile
    class="text-center align-center d-flex"
  >
    <v-card-text
      class="mb-15 pb-15"
    >
      <v-progress-circular
        indeterminate
        color="orange"
        size="50"
      ></v-progress-circular>
    </v-card-text>
  </v-card>
</template>

<script>
export default {

}
</script>

<style>

</style>