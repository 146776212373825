<template>
  <v-app-bar
    app
    elevation="0"
    dark
  >
    <logo v-if="searchBoxClosed" />
    <v-spacer></v-spacer>
    <!-- <v-icon large>mdi-magnify</v-icon> -->
    <!-- <search /> -->
    <template  v-slot:extension>
      <component v-bind:is="extensionToInject"></component>
    </template>
  </v-app-bar>
</template>

<script>
import Logo from './Logo.vue'
import PostsExtension from './PostsExtension.vue'
import BackExtension from './BackExtension.vue'
import SearchViewExtension from './SearchViewExtension.vue'
import Search from './Search.vue'

export default {
  components: { Logo, Search },
  data: () => ({

  }),
  computed: {
    searchBoxClosed() {
      return this.$store.state.searchBoxClosed
    },
    extensionToInject() {
      const ext = this.$route.meta.appBarExtention
      if (ext === 'PostsExtension') return PostsExtension
      if (ext === 'BackExtension') return BackExtension
      if (ext === 'SearchViewExtension') return SearchViewExtension
      return null
    }
  }

}
</script>

<style>

</style>