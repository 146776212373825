<template>
  <v-bottom-navigation app class="align-center pa-4" dark height="auto">
    <v-card width="100%" color="transparent" flat>
      <v-card-actions class="d-flex flex pa-0">
        <v-form
          v-model="isValid"
          class="flex"
          @submit.prevent
        >
        <!-- @keydown.enter="addComment" -->
          <v-textarea
            placeholder="Comment"
            v-model="comment"
            rows="1"
            auto-grow
            :rules="commentRules"
            class="mt-0 pt-0"
            :error-messages="error"
            lazy-validation
          ></v-textarea>
        </v-form>
        <v-btn
          @click="addComment"
          plain
          :disabled="!isValid"
        >
          Send
          <v-icon>mdi-send</v-icon>
        </v-btn>
      </v-card-actions>
      <v-card-text class="pa-0 text-caption red--text text--darken-4 font-weight-bold text-center">
        You cannot delete or modify your comments after sending.<br/>It could be deleted by Admin or AI
      </v-card-text>
    </v-card>
  </v-bottom-navigation>
</template>

<script>
export default {
  data: () => ({
    isValid: false,
    comment: '',
    error: null,
    commentRules:
    [
      (v) => !!v || "Message is required",
      (v) => (v && v.length < 301) || "Message must be 300 characters or less",
    ],
  }),
  methods: {
    async addComment() {
      const data = {
        body: this.comment,
        id: this.$route.params.id
      }
      if (await this.$store.dispatch('addComment', data)) {
        this.comment = null
        this.commentRules = null
        this.$emit('commented', this.$route.params.id)
        window.scrollTo(0, document.body.scrollHeight);
      } else {
        this.error = 'Something went wrong'
      }
    }
  },

}
</script>

<style>

</style>