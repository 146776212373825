<template>
  <div>
    <v-tabs-items v-model="tabs.selectedTab">
      <v-tab-item>
        <v-card
          flat
          tile
        >
          <trending />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card
          flat
          tile
        >
          <new-posts />
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Trending from '../components/posts/Trending.vue'
import NewPosts from '../components/posts/NewPosts.vue'

export default {
  computed: {
    tabs() {
      return this.$store.state.postsTabState
    },
  },
  watch: {
    tabs() {
      const i = this.tabs.selectedTab
      this.$store.commit('setTab', i)
    }
  },
  components: { Trending, NewPosts },
}
</script>